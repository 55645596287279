import { css } from '@emotion/core'
//import { mq } from '../../cssInJs'
export default{
    exTextItem : css({
        overflow:'hidden',
        maxHeight:'0',
        //transition: 'all 0.5s ease',
        transition : 'max-height 1s cubic-bezier(0, 1, 0, 1)',
        padding:'0px',
        //opacity : "0.2",
        //padding: '0px',
        '&.expand' : {
            maxHeight:'1000px',
            transition: 'max-height 1s ease-in-out'
            //padding: '1rem 0',
            //opacity : '1'
        },
        // '&.contentPanel' : { 
        // }
    })
}