const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',

  primaryColor: '#c20f2f',
  darkGrayColor: '#3b3b3b',
  copperColor : '#b85300',
  darkGreenColor : '#006838',
  fontColor: '#3b3b3b',
  bgColor: '#ebebeb',
  ...overloading
}
export default variables;